import Vue from 'vue'
import App from './App.vue'

let vue = Vue
let idgtlWidgetMounted = false

/**
 * @param formID {string}
 * @param mountAt {string}
 * @param options {object}
 * @param onSuccessCallback {function}
 * @returns {void}
 */
export const tildaPrepare = (formID, mountAt = '', options = {}, onSuccessCallback = null) => {
  function prepareForm () {
    Vue.prototype.$tildaFormID = formID

    const form = document.querySelector(`${formID} form`) || document.querySelector(`form${formID}`)

    if (!form) {
      console.warn(`[verify-widget]: Формы по ID ${formID} не найдено`)
      return
    }

    const formButton = form.querySelector('button')
    const tFormSubmitBlock = form.querySelector('.t-form__submit') || form.querySelector('.tn-form__submit')

    console.warn('tFormSubmitBlock', tFormSubmitBlock)

    const widgetButton = document.createElement('button')
    const attributes = Array.prototype.slice.call(formButton.attributes)

    attributes.forEach(attr => {
      widgetButton.setAttribute(attr.nodeName, attr.nodeValue)
    })

    widgetButton.setAttribute('type', 'button')
    widgetButton.style.display = ''
    widgetButton.innerText = formButton.innerText
    widgetButton.classList.add('idirect-widget-tilda-button')
    tFormSubmitBlock.appendChild(widgetButton)
    formButton.style.display = 'none'
    formButton.disabled = true

    widgetButton.addEventListener('click', function () { validateAndMountWidget(formID, mountAt, options, onSuccessCallback) }, false)
  }

  if (!formID || formID === '#') {
    console.warn('[verify-widget]: Укажите ID формы Tilda')
    return
  }

  if (!mountAt || mountAt === '#') {
    console.warn('[verify-widget]: Укажите ID элемента для монтирования виджета')
    return
  }

  document.addEventListener('DOMContentLoaded', prepareForm)
}

const validateAndMountWidget = (formID, mountAt, options = {}, onSuccessCallback = null) => {
  if (idgtlWidgetMounted) {
    const widgetEl = document.querySelector('#idgtl-2fa-widget')

    if (widgetEl) {
      unmount(mountAt)
    }
  }

  const inputTel = document.querySelector(`${formID} input[type="tel"]`)
  const inputMask = document.querySelector(`${formID} .t-input-phonemask__select-code`)
  const formPhoneMask = document.querySelector(`${formID} .t-input-phonemask__select`)
  let destination = ''
  let inputTelBlock = null

  if (inputTel) {
    inputTelBlock = inputTel.closest('.t-input-group')
  }

  if (formPhoneMask) {
    formPhoneMask.style.pointerEvents = 'none'
  }

  if (inputTel.value) {
    inputTel.setAttribute('readonly', 'true')
    const inputTelValue = inputTel.value.replace(/[)(\-\s]/gmi, '')

    if (inputMask) {
      destination = inputMask.innerHTML + inputTelValue
    } else {
      destination = inputTelValue
    }

    if (inputTelBlock) inputTelBlock.classList.remove('js-error-control-box')

    const widgetOptions = {
      ...options,
      destination
    }

    mount(mountAt, widgetOptions, () => {}, onSuccessCallback)
  } else {
    if (inputTelBlock) inputTelBlock.classList.add('js-error-control-box')
  }
}

/**
 * @param mountAt {string}
 * @param options {object}
 * @param checkCallback {function}
 * @param onSuccessCallback {function}
 * @returns {void}
 */
export const mount = (mountAt = '', options = {}, checkCallback = null, onSuccessCallback = null) => {
  if (!mountAt || mountAt === '#') {
    console.warn('[verify-widget]: You must provide DOM element to mount to')
    return
  }

  if (!document.querySelector(mountAt)) {
    console.warn(`[verify-widget]: There's no element with id ${mountAt} in DOM`)
    return
  }

  if (options && options instanceof Object) {
    Vue.prototype.$widgetOptions = JSON.parse(JSON.stringify(options))
  }

  Vue.prototype.$widgetCheckCallback = checkCallback
  Vue.prototype.$widgetOnSuccessCallback = onSuccessCallback

  const mountAtElement = document.querySelector(mountAt)
  const widgetContainer = document.createElement('div')
  widgetContainer.id = 'widget-main-container'
  mountAtElement.appendChild(widgetContainer)

  vue = new Vue({
    render: h => h(App)
  })

  vue.$mount('#widget-main-container')

  idgtlWidgetMounted = true
}

export const unmount = () => {
  if (vue.$children && vue.$children[0] && vue.$children[0].removeWidget) {
    vue.$children[0].removeWidget()
  }
}
