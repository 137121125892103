import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5061b95c&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=5061b95c&prod&lang=css"
import style1 from "./index.vue?vue&type=style&index=1&id=5061b95c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5061b95c",
  null
  
)

export default component.exports